var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-container",
    { attrs: { fluid: "", "data-cy": "insulationjobs" } },
    [
      _vm.inlineMessage.message
        ? _c(
            "v-alert",
            {
              staticClass: "mt-0 mb-3",
              attrs: { type: _vm.inlineMessage.type }
            },
            [_vm._v("\n    " + _vm._s(_vm.inlineMessage.message) + "\n  ")]
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "v-card",
        { staticClass: "fd-view" },
        [
          _c(
            "fd-toolbar",
            {
              attrs: { loading: _vm.processing },
              scopedSlots: _vm._u([
                {
                  key: "extension",
                  fn: function() {
                    return [
                      _c("fd-add-button", {
                        attrs: {
                          "data-cy": "addbutton",
                          action: _vm.openNewJobDialog
                        }
                      })
                    ]
                  },
                  proxy: true
                }
              ])
            },
            [
              _c(
                "v-row",
                [
                  _c("v-col", { attrs: { cols: "12", sm: "4" } }, [
                    _c(
                      "div",
                      {
                        class: [
                          _vm.processing ? "breadcrumb-processing-opacity" : ""
                        ],
                        staticStyle: {
                          "font-size": "1.40rem !important",
                          position: "relative"
                        }
                      },
                      [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              position: "absolute",
                              top: "0px",
                              left: "0px"
                            }
                          },
                          [
                            _c(
                              "div",
                              { staticStyle: { position: "relative" } },
                              [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(
                                      _vm.$t("job.insulation.list.title")
                                    ) +
                                    "\n                "
                                ),
                                !!_vm.curUserAccess.homeContractorID &&
                                !_vm.curUserAccess.includesAllContractors
                                  ? _c("span", [
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "hide-when-no-contractor-class"
                                        },
                                        [_vm._v("|")]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "fd-view-header-contractor-qualifier-label"
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                "margin-left": "6px"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                "\n                      " +
                                                  _vm._s(
                                                    _vm.$t(
                                                      "contractors.contractor-select"
                                                    )
                                                  ) +
                                                  "\n                    "
                                              )
                                            ]
                                          )
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "fd-view-header-contractor-qualifier-contractor-detail-positioning"
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              class: [
                                                _vm.$vuetify.breakpoint.xs
                                                  ? "fd-view-header-contractor-qualifier-contractor-detail-xs-width-restrictor"
                                                  : "",
                                                "fd-view-header-contractor-qualifier-contractor-detail"
                                              ]
                                            },
                                            [
                                              _vm._v(
                                                "\n                      " +
                                                  _vm._s(
                                                    _vm.curUserContractorName
                                                  ) +
                                                  "\n                    "
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    ])
                                  : _vm._e()
                              ]
                            )
                          ]
                        )
                      ]
                    )
                  ]),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { attrs: { cols: "0", sm: "8" } },
                    [
                      _c(
                        "v-row",
                        {
                          staticClass: "d-none d-sm-flex",
                          attrs: { justify: "end" }
                        },
                        [
                          _c("v-combobox", {
                            class: [
                              _vm.tagsSelectedForFiltering.length > 0
                                ? "fd-combobox-control-item-selected"
                                : "fd-combobox-control-item-not-selected",
                              "pr-3",
                              "fd-limit-combobox-size-to-content"
                            ],
                            attrs: {
                              items: _vm.tagsInUse,
                              label: _vm.$t("common.filter-by-tags"),
                              "item-text": "name",
                              "item-value": "id",
                              multiple: "",
                              "small-chips": "",
                              clearable: ""
                            },
                            model: {
                              value: _vm.tagsSelectedForFiltering,
                              callback: function($$v) {
                                _vm.tagsSelectedForFiltering = $$v
                              },
                              expression: "tagsSelectedForFiltering"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-card-subtitle",
            { staticStyle: { "margin-top": "15px" } },
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { class: ["pt-0", "pb-5", "mt-5"], attrs: { cols: "12" } },
                    [
                      _c(
                        "v-row",
                        {
                          staticClass: "pa-0 ma-0",
                          attrs: { align: "center", justify: "center" }
                        },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "mr-5",
                              attrs: {
                                "data-cy": "reload",
                                small: "",
                                rounded: "",
                                outlined: "",
                                color: "#7a7a7a"
                              },
                              on: { click: _vm.reloadTableData }
                            },
                            [
                              !_vm.$vuetify.breakpoint.sm
                                ? _c(
                                    "v-icon",
                                    {
                                      attrs: {
                                        small: "",
                                        left: _vm.$vuetify.breakpoint.smAndUp
                                      }
                                    },
                                    [_vm._v("mdi-reload")]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.$vuetify.breakpoint.smAndUp
                                ? _c("span", [
                                    _vm._v(_vm._s(_vm.$t("common.reload")))
                                  ])
                                : _vm._e()
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("v-text-field", {
                            class: [
                              _vm.$vuetify.breakpoint.sm
                                ? "fd-restrict-clearable-position"
                                : "",
                              "pt-0",
                              "mt-0",
                              "fd-table-search-input"
                            ],
                            attrs: {
                              "data-cy": "tablesearch",
                              "append-icon": "search",
                              label: _vm.$t("common.search"),
                              "single-line": "",
                              "hide-details": "",
                              clearable: ""
                            },
                            model: {
                              value: _vm.tablesearch,
                              callback: function($$v) {
                                _vm.tablesearch = $$v
                              },
                              expression: "tablesearch"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("v-data-table", {
            directives: [
              {
                name: "fd-column",
                rawName: "v-fd-column:internalNumber",
                value: _vm.$t("job.job-number"),
                expression: "$t('job.job-number')",
                arg: "internalNumber"
              },
              {
                name: "fd-column",
                rawName: "v-fd-column:jobStatusID",
                value: _vm.$t("job.job-status"),
                expression: "$t('job.job-status')",
                arg: "jobStatusID"
              },
              {
                name: "fd-column",
                rawName: "v-fd-column:formattedWorkOrderNumber",
                value: _vm.$t("job.work-order"),
                expression: "$t('job.work-order')",
                arg: "formattedWorkOrderNumber"
              },
              {
                name: "fd-column",
                rawName: "v-fd-column:latestWorkOrderAreaName",
                value: _vm.$t("job.work-order-area"),
                expression: "$t('job.work-order-area')",
                arg: "latestWorkOrderAreaName"
              },
              {
                name: "fd-column",
                rawName: "v-fd-column:latestWorkOrderSubAreaName",
                value: _vm.$t("job.work-order-sub-area"),
                expression: "$t('job.work-order-sub-area')",
                arg: "latestWorkOrderSubAreaName"
              },
              {
                name: "fd-column",
                rawName: "v-fd-column:status",
                value: _vm.$t("job.work-order-status"),
                expression: "$t('job.work-order-status')",
                arg: "status"
              },
              {
                name: "fd-column",
                rawName: "v-fd-column:latestWorkOrderStarted",
                value: _vm.$t("job.work-order-started"),
                expression: "$t('job.work-order-started')",
                arg: "latestWorkOrderStarted"
              },
              {
                name: "fd-column",
                rawName: "v-fd-column:latestWorkOrderCompleted",
                value: _vm.$t("job.work-order-completed"),
                expression: "$t('job.work-order-completed')",
                arg: "latestWorkOrderCompleted"
              },
              {
                name: "fd-column",
                rawName:
                  "v-fd-column:archived.hide-when-extra-small.hide-when-header-text-empty",
                value: _vm.showArchived ? _vm.$t("common.archived") : "",
                expression:
                  "\n        showArchived ? $t('common.archived') : ''\n      ",
                arg: "archived",
                modifiers: {
                  "hide-when-extra-small": true,
                  "hide-when-header-text-empty": true
                }
              },
              {
                name: "fd-column",
                rawName:
                  "v-fd-column:action.no-sort.hide-when-extra-small.class_fd-action-cell",
                arg: "action",
                modifiers: {
                  "no-sort": true,
                  "hide-when-extra-small": true,
                  "class_fd-action-cell": true
                }
              },
              {
                name: "fd-row-navigate",
                rawName: "v-fd-row-navigate.show-when-extra-small",
                value: item => _vm.$router.push(`/insulation/${item.id}`),
                expression: "item => $router.push(`/insulation/${item.id}`)",
                modifiers: { "show-when-extra-small": true }
              }
            ],
            class: [
              { "fd-archive-bonus-padding-bottom": _vm.showArchived },
              "fd-requests-list",
              "fd-enabling-table"
            ],
            attrs: {
              "data-cy": "list",
              items: _vm.jobs,
              search: _vm.tablesearch,
              loading: _vm.processing,
              "loading-text": _vm.$t("common.table-loading-message"),
              "sort-by": ["internalNumber"],
              "sort-desc": true,
              "mobile-breakpoint": "0"
            },
            scopedSlots: _vm._u([
              {
                key: "footer.prepend",
                fn: function() {
                  return [
                    _c("fd-archived-data-loader", {
                      staticClass: "ml-2 mr-3",
                      attrs: {
                        showArchived: _vm.showArchived,
                        showArchivedDateRange: _vm.showArchivedDateRange,
                        showArchivedMinDate: _vm.showArchivedMinDate,
                        showArchivedMaxDate: _vm.showArchivedMaxDate,
                        loading: _vm.archivedLoading,
                        disabled: _vm.processing
                      },
                      on: {
                        "update:showArchived": function($event) {
                          _vm.showArchived = $event
                        },
                        "update:show-archived": function($event) {
                          _vm.showArchived = $event
                        },
                        "update:showArchivedDateRange": function($event) {
                          _vm.showArchivedDateRange = $event
                        },
                        "update:show-archived-date-range": function($event) {
                          _vm.showArchivedDateRange = $event
                        }
                      }
                    })
                  ]
                },
                proxy: true
              },
              {
                key: "item.fd-nav",
                fn: function() {
                  return [_c("v-icon", [_vm._v("mdi-chevron-right")])]
                },
                proxy: true
              },
              {
                key: "item.archived",
                fn: function({ item }) {
                  return [
                    _vm.showArchived
                      ? _c(
                          "div",
                          [
                            _c("v-simple-checkbox", {
                              attrs: { value: item.archived, disabled: true }
                            })
                          ],
                          1
                        )
                      : _vm._e()
                  ]
                }
              },
              {
                key: "item.jobStatusID",
                fn: function({ item }) {
                  return [
                    _c("fd-value-display", {
                      attrs: {
                        value: _vm.$t(`job.statuses.${item.jobStatusID}`)
                      }
                    })
                  ]
                }
              },
              {
                key: "item.internalNumber",
                fn: function({ item }) {
                  return [
                    _c("fd-value-display", {
                      attrs: { value: item.formattedInternalNumber }
                    })
                  ]
                }
              },
              {
                key: "item.status",
                fn: function({ item }) {
                  return [
                    _c("fd-value-display", {
                      attrs: {
                        value: !!item.latestWorkOrder
                          ? _vm.$t(
                              `workorders.status.${item.latestWorkOrder.workOrderStatus}`
                            )
                          : ""
                      }
                    })
                  ]
                }
              },
              {
                key: "item.latestWorkOrderStarted",
                fn: function({ item }) {
                  return [
                    _c("fd-value-display", {
                      attrs: {
                        value: _vm.formatDate(item.latestWorkOrderStarted)
                      }
                    })
                  ]
                }
              },
              {
                key: "item.latestWorkOrderCompleted",
                fn: function({ item }) {
                  return [
                    _c("fd-value-display", {
                      attrs: {
                        value: _vm.formatDate(item.latestWorkOrderCompleted)
                      }
                    })
                  ]
                }
              },
              {
                key: "item.action",
                fn: function({ item }) {
                  return [
                    _c(
                      "div",
                      { staticClass: "table-1-action-column-min-width" },
                      [
                        _c(
                          "v-tooltip",
                          {
                            attrs: {
                              top: "",
                              disabled:
                                !_vm.$vuetify.breakpoint.lgAndUp ||
                                _vm.processing
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function({ on }) {
                                    return [
                                      _c(
                                        "v-btn",
                                        _vm._g(
                                          {
                                            attrs: {
                                              icon: "",
                                              link: "",
                                              disabled: _vm.processing,
                                              to: `/insulation/${item.id}`
                                            }
                                          },
                                          on
                                        ),
                                        [
                                          _c(
                                            "v-icon",
                                            { attrs: { small: "" } },
                                            [
                                              _vm._v(
                                                "\n                  mdi-pencil\n                "
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              true
                            )
                          },
                          [
                            _vm._v(" "),
                            _c("span", [_vm._v(_vm._s(_vm.$t("common.edit")))])
                          ]
                        )
                      ],
                      1
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }