import FDVue from "@fd/lib/vue";
import serviceErrorHandling from "@fd/lib/vue/mixins/serviceErrorHandling";
import { mapActions, mapMutations } from "vuex";
import tabbedView, { Tab } from "@fd/lib/vue/mixins/tabbedView";
import {
  Contractor,
  jobService,
  JobStatuses,
  JobWithData,
  ProjectLocation,
  projectLocationService,
  Tag
} from "../services";
import { FormatJobData, FormattedJob } from "./JobsListPaint.vue";
import { FDColumnDirective } from "@fd/lib/vue/utility/dataTable";
import * as DateUtil from "@fd/lib/client-util/datetime";

type Keyword = Tag;
export default FDVue.extend({
  name: "fd-paint-job-existing",

  mixins: [serviceErrorHandling, tabbedView],

  directives: {
    fdColumn: FDColumnDirective
  },

  components: {
    "fd-chip-selector": () => import("@fd/lib/vue/components/ChipItemSelector.vue"),
    "fd-work-order-details": () => import("../views/components/WorkOrderDetailsForm.vue")
  },

  data: function() {
    return {
      slidein: false,
      saving: false,
      releasing: false,

      firstTabKey: `1`,
      detailsTab: {
        tabname: this.$t("job.paint.existing.tabs.details"),
        key: `1`,
        visible: true
      } as Tab,
      workOrdersTab: {
        tabname: this.$t("job.paint.existing.tabs.work-orders"),
        key: `2`,
        visible: false
      } as Tab,

      /*** KEYWORDS ***/
      selectedKeywords: [] as Keyword[],

      workOrderTableSearch: "",
      allAreas: [] as ProjectLocation[],
      allSubAreas: [] as ProjectLocation[],

      job: {} as FormattedJob,
      expanderColSpan: 10
    };
  },

  computed: {
    // *** GLOBAL ***
    tabDefinitions(): Tab[] {
      // Details is not included since it's the first tab and is always visible
      return [this.workOrdersTab] as Tab[];
    },
    // *** DETAILS ***
    availableKeywords(): Keyword[] {
      return this.$store.getters.sortedEnabledTags;
    },
    allKeywords(): Keyword[] {
      return this.$store.state.tags.fullList as Keyword[];
    },
    jobCanBeReleased(): boolean {
      return this.job.jobStatusID == JobStatuses.Draft;
    }
  },

  watch: {
    job() {
      if ((this.$store.state.lastBreadcrumbs[0]?.to || "") != "/paint") {
        this.notifyNewBreadcrumb({
          text: this.$t("job.paint.list.title"),
          to: "/paint",
          resetHistory: true
        });
        // This is needed in order to salvage the "last breadcrumbs" in the store.
        this.$store.commit("NOTIFY_NAVIGATION_STARTED");
      }
      this.notifyNewBreadcrumb({
        text: this.job.formattedInternalNumber,
        to: `/paint/${this.$route.params.id}`
      });
    }
  },

  methods: {
    ...mapActions({}),
    ...mapMutations({
      notifyNewBreadcrumb: "NOTIFY_NEW_BREADCRUMB",
      setFilteringContext: "SET_FILTERING_CONTEXT"
    }),
    onSubmit(e: Event) {
      e.preventDefault();
      return false;
    },
    preventSubmit(e: Event) {
      e.preventDefault();
      return false;
    },
    // Method used in conjunction with the Cancel button.
    cancel() {
      this.$router.push("/paint");
    },
    async releaseJob() {
      this.processing = true;
      this.releasing = true;
      try {
        if (!(await this.saveJobData())) {
          this.processing = false;
          this.saving = false;
          return;
        }

        await jobService.releaseJobByID(this.$route.params.id);

        var snackbarPayload = {
          text: this.$t("job.paint.existing.submit-success", [this.job.internalNumber]),
          type: "success",
          undoCallback: null
        };
        this.$store.dispatch("SHOW_SNACKBAR", snackbarPayload);
        this.$router.push("/paint");
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
        this.releasing = false;
      }
    },
    async save(closeOnComplete: boolean) {
      this.processing = true;
      this.saving = true;
      try {
        if (!(await this.saveJobData())) {
          this.processing = false;
          this.saving = false;
          return;
        }

        if (closeOnComplete) this.$router.push("/paint");
        else this.loadJobDetails();
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
        this.saving = false;
      }
    },
    async saveJobData() {
      var archivedDate = null;
      if (this.job.archived && !this.job.archivedDate) {
        archivedDate = new Date(new Date().toUTCString());
      }
      this.job.archivedDate = archivedDate;
      this.job.tagIDs =
        this.selectedKeywords.length > 0 ? this.selectedKeywords.map(x => x.id!) : undefined;

      await jobService.updateItem(this.job.id!, this.job);
      return true;
    },
    async loadJobDetails() {
      let job = FormatJobData(await jobService.getByID(this.$route.params.id));
      this.job = {
        ...job,
        relatedWorkOrders: job.relatedWorkOrders.map(x => ({
          ...x,
          formattedRequestDate: DateUtil.stripTimeFromLocalizedDateTime(x.created),
          formattedCompletedDate: DateUtil.stripTimeFromLocalizedDateTime(x.completedDate)
        }))
      };
      this.selectedKeywords = this.job.tagIDs
        ? (this.job.tagIDs
            .map(x => this.allKeywords.find(y => y.id == x))
            .filter(x => !!x) as Keyword[])
        : [];
    }
  },

  created: async function() {
    if ((this.$store.state.lastBreadcrumbs[0]?.to || "") != "/paint") {
      this.notifyNewBreadcrumb({
        text: this.$t("job.paint.list.title"),
        to: "/paint",
        resetHistory: true
      });
      // This is needed in order to salvage the "last breadcrumbs" in the store.
      this.$store.commit("NOTIFY_NAVIGATION_STARTED");
    }

    this.processing = true;

    // Add a small delay of time before the view comes in so that the "slide in" animation will be seen by the user.
    setInterval(() => {
      this.slidein = true;
    }, 100);

    // Set the context for the User Filtering in the store so that if the user navigates to a screen that is
    // a sub screen of something that is currently filtered by their choices that those choices will be
    // preserved as they move between the two screens.
    this.setFilteringContext({
      context: "paint-existing",
      parentalContext: "paintjobs",
      searchStringForFiltering: "",
      selectedTab: this.firstTabKey
    });

    try {
      await this.$store.dispatch("LOAD_TAGS");
      await this.loadJobDetails();
    } catch (error) {
      this.handleError(error as Error);
    } finally {
      this.processing = false;
    }
  }
});

