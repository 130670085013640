import FDVue from "@fd/lib/vue";
import { mapMutations } from "vuex";
import { DataItemProps, DataTableCompareFunction, DataTableHeader } from "vuetify";
import rules from "@fd/lib/vue/rules";
import { valueInArray } from "@fd/lib/client-util/array";
import * as DateUtil from "@fd/lib/client-util/datetime";
import ServiceError from "@fd/lib/client-util/serviceError";
import { SortableEvent } from "sortablejs";

import {
  ScaffoldRequestSubTypeLabels,
  ScaffoldRequestTypeLabels,
  WorkOrderStatusLabels
} from "../store/referenceData/enum";
import { contractorDataStore } from "../store/referenceData";
import { personDataStore } from "../store/referenceData";
import { filterByTags, TaggableItem } from "../services/taggableItems";
import {
  ScaffoldRequestTypes,
  WorkOrderForSchedulerGrid,
  WorkOrderExtraDetailsForSchedulerGrid,
  WorkOrderStatuses,
  workOrderService,
  workOrderSchedulerService,
  workOrderStatusHelper,
  WalkdownExtraDetailsForSchedulerGrid,
  WorkOrderPackageForSchedulerGrid,
  reportService,
  WorkOrderToPrint,
  personalSettingService,
  PersonalSetting
} from "../services";
import { showAdditionalDetailsDialog } from "../../../common/client/views/components/AdditionalDetailsDialog.vue";
import { openActiveWorkForScaffoldDialog } from "./components/ActiveWorkForScaffoldDialog.vue";

import WorkOrderDetails, {
  WorkOrderDetailRecord,
  WalkdownDetailRecord
} from "../views/components/data/workOrderDetails/WorkOrderDetails.vue";

import workOrderList, {
  WorkOrderWithExtraDetails,
  WorkForScaffoldDetailsFromWorkOrder,
  FilteringRequestorContext,
  FilteringContext,
  CalculateRequiredDateDaysPushed
} from "../dataMixins/workOrderList";
import { GetPersonName, PersonWithName } from "../utils/person";

import {
  createStickyCustomSort,
  defaultStickySortFunction
} from "@fd/lib/vue/utility/dataTableStickySort";
import { VDataTable } from "../../../lib/vue/types";
import downloadBlob from "../../../lib/client-util/downloadBlob";
import printBlob from "../../../lib/client-util/printBlob";
import { disciplineDataStore } from "../store/referenceData";
import { projectLocationDataStore as locationDataStore } from "../store/referenceData";
import { TranslateResult } from "vue-i18n";
import { FDTableSortableHeadersDirective } from "../../../lib/vue/utility/dataTable";
import { HashTable } from "../utils/timesheet";
import { showWorkorderFastForwardDialog } from "./components/dialogs/SP.WorkOrderFastForwardDialog.vue";
import { showThreeWeekLookAheadParametersDialog } from "./components/dialogs/SP.ThreeWeekLookAheadParametersDialog.vue";

interface WorkOrderForSchedulerGridWithDetails extends WorkOrderForSchedulerGrid {
  workOrderDetails: WorkOrderDetailRecord | { isLoading: true };
  walkdownDetails: WalkdownDetailRecord | { isLoading: true };
}

function sortWithHotItemsOnTop(
  sortValuesA: any[],
  sortValuesB: any[],
  itemA: WorkOrderForSchedulerGridWithDetails,
  itemB: WorkOrderForSchedulerGridWithDetails,
  sortBy: string[],
  sortDesc: boolean[],
  customSorters?: Record<string, DataTableCompareFunction>
) {
  if (itemA.isUrgent != itemB.isUrgent) {
    return itemA.isUrgent ? -1 : 1;
  } else {
    return defaultStickySortFunction(
      sortValuesA,
      sortValuesB,
      itemA,
      itemB,
      sortBy,
      sortDesc,
      customSorters
    );
  }
}

type TranslatableHeader = Omit<DataTableHeader, "text" | "class" | "cellClass"> & {
  order: number;
  text: string | TranslateResult;
  canReorder: boolean;
  class: string | string[] | object | object[] | undefined;
  cellClass: string | string[] | object | object[] | undefined;
};
function getHiddenColumnHeader(value: string): TranslatableHeader {
  return {
    order: -1,
    canReorder: false,
    text: "",
    value: value,
    sortable: false,
    class: "d-none",
    cellClass: "d-none"
  };
}

export default FDVue.extend({
  name: "fd-work-order-scheduler",

  directives: {
    fdSortableTable: FDTableSortableHeadersDirective
  },

  components: {
    "fd-work-order-details": WorkOrderDetails,
    "sp-inline-scope-icons": () => import("./components/SP.InlineScopeIcons.vue")
  },
  mixins: [rules, workOrderList],

  data: function() {
    return {
      stickySortFilter: createStickyCustomSort(sortWithHotItemsOnTop),
      workOrderDetailsByID: null as Map<string, WorkOrderExtraDetailsForSchedulerGrid> | null,
      walkdownDetailsByID: null as Map<string, WalkdownExtraDetailsForSchedulerGrid> | null,
      workPackagesByID: null as Map<string, WorkOrderPackageForSchedulerGrid[]> | null,
      expanderExpandCount: 0,
      anIncreasingNumber: 0,
      headerOrders: {
        priority: 0,
        progress: 1,
        internalNumber: 2,
        scaffoldNumber: 3,
        requestType: 4,
        workOrderStatus: 5,
        requiredDate: 6,
        coordinatorName: 7,
        generalForemanName: 8,
        foremanName: 9,
        assignedContractor: 10,
        todo: 11,
        archived: 12,
        actions: 13,
        "fd-nav": 14
      } as HashTable<number>
    };
  },

  computed: {
    allHeaders: {
      cache: false,
      get(): TranslatableHeader[] {
        var allHeaders = [
          getHiddenColumnHeader("id"),
          getHiddenColumnHeader("requestNumber"),
          {
            order: this.headerOrders.priority ?? 0,
            value: "priority",
            canReorder: true,
            text: this.$t("scheduler.priority"),
            class: [{ "d-none": this.$vuetify.breakpoint.smAndDown }, "fd-header-drag-handle"],
            cellClass: [{ "d-none": this.$vuetify.breakpoint.smAndDown }]
          },
          {
            order: this.headerOrders.progress ?? 0,
            value: "progress",
            canReorder: true,
            text: this.$t("scheduler.progress-short"),
            class: [{ "d-none": this.$vuetify.breakpoint.lgAndDown }, "fd-header-drag-handle"],
            cellClass: [{ "d-none": this.$vuetify.breakpoint.lgAndDown }]
          },
          {
            order: this.headerOrders.internalNumber ?? 0,
            value: "internalNumber",
            canReorder: true,
            text: this.$vuetify.breakpoint.lgAndUp
              ? this.$t("scheduler.wo-number-column-header")
              : this.$t("scheduler.wo-number-column-header-short"),
            class: [{ "d-none": this.$vuetify.breakpoint.mdAndDown }, "fd-header-drag-handle"],
            cellClass: [{ "d-none": this.$vuetify.breakpoint.mdAndDown }]
          },
          {
            order: this.headerOrders.scaffoldNumber ?? 0,
            value: "scaffoldNumber",
            canReorder: true,
            text: this.$vuetify.breakpoint.lgAndUp
              ? this.$t("scheduler.scaffold-number")
              : this.$t("scheduler.scaffold-number-short"),
            class: [{ "d-none": false }, "fd-header-drag-handle"],
            cellClass: [{ "d-none": false }]
          },
          {
            order: this.headerOrders.requestType ?? 0,
            value: "requestType",
            canReorder: true,
            text: this.$vuetify.breakpoint.lgAndUp
              ? this.$t("scheduler.request-type-column-header")
              : this.$t("scheduler.request-type-column-header-short"),
            class: [{ "d-none": this.$vuetify.breakpoint.mdAndDown }, "fd-header-drag-handle"],
            cellClass: [{ "d-none": this.$vuetify.breakpoint.mdAndDown }]
          },
          //v-fd-column:([a-z]*)[.a-z\-]*="([\n\t $t\(\)'a-z.?:-]*)"
          {
            order: this.headerOrders.workOrderStatus ?? 0,
            value: "workOrderStatus",
            canReorder: true,
            text: this.$t("scheduler.status"),
            class: [{ "d-none": false }, "fd-header-drag-handle"],
            cellClass: [{ "d-none": false }]
          },
          {
            order: this.headerOrders.requiredDate ?? 0,
            value: "requiredDate",
            canReorder: true,
            text: this.$vuetify.breakpoint.lgAndUp
              ? this.$t("scheduler.required-date-column-header")
              : this.$t("scheduler.required-date-column-header-short"),
            class: [{ "d-none": this.$vuetify.breakpoint.xsOnly }, "fd-header-drag-handle"],
            cellClass: [{ "d-none": this.$vuetify.breakpoint.xsOnly }]
          },
          {
            order: this.headerOrders.coordinatorName ?? 0,
            value: "coordinatorName",
            canReorder: true,
            text: this.$t("scheduler.coordinator"),
            class: [{ "d-none": this.$vuetify.breakpoint.smAndDown }, "fd-header-drag-handle"],
            cellClass: [{ "d-none": this.$vuetify.breakpoint.smAndDown }]
          },
          {
            order: this.headerOrders.generalForemanName ?? 0,
            value: "generalForemanName",
            canReorder: true,
            text: this.$vuetify.breakpoint.lgAndUp
              ? this.$t("scheduler.general-foreman-short")
              : this.$t("scheduler.general-foreman-short"),
            class: [{ "d-none": this.$vuetify.breakpoint.xsOnly }, "fd-header-drag-handle"],
            cellClass: [{ "d-none": this.$vuetify.breakpoint.xsOnly }]
          },
          {
            order: this.headerOrders.foremanName ?? 0,
            value: "foremanName",
            canReorder: true,
            text: this.$t("scheduler.foreman-short"),
            class: [{ "d-none": this.$vuetify.breakpoint.xsOnly }, "fd-header-drag-handle"],
            cellClass: [{ "d-none": this.$vuetify.breakpoint.xsOnly }]
          },
          {
            order: this.headerOrders.assignedContractor ?? 0,
            value: "assignedContractor",
            canReorder: true,
            text: this.$t("scheduler.assigned-contractor"),
            class: [{ "d-none": this.$vuetify.breakpoint.lgAndDown }, "fd-header-drag-handle"],
            cellClass: [{ "d-none": this.$vuetify.breakpoint.lgAndDown }]
          },
          {
            order: this.headerOrders.todo ?? 0,
            value: "todo",
            canReorder: false,
            text: this.$t("scheduler.to-do"),
            class: [{ "d-none": false }],
            cellClass: [{ "d-none": false }]
          },
          {
            order: this.headerOrders.archived ?? 0,
            value: "archived",
            canReorder: false,
            text: this.showArchived ? this.$t("common.archived") : "",
            class: [{ "d-none": !this.showArchived || this.$vuetify.breakpoint.mobile }],
            cellClass: [{ "d-none": !this.showArchived || this.$vuetify.breakpoint.mobile }]
          },
          {
            order: this.headerOrders.actions ?? 0,
            value: "actions",
            canReorder: false,
            text: this.$t("common.actions"),
            sortable: false,
            class: [{ "d-none": this.$vuetify.breakpoint.xsOnly }, "fd-actions-cell"],
            cellClass: [{ "d-none": this.$vuetify.breakpoint.xsOnly }, "fd-actions-cell"]
          },
          {
            order: this.headerOrders["fd-nav"] ?? 0,
            text: "",
            value: "fd-nav",
            canReorder: false,
            sortable: false,
            class: [{ "d-none": this.$vuetify.breakpoint.smAndUp }, "fd-navigation-cell"],
            cellClass: [{ "d-none": this.$vuetify.breakpoint.smAndUp }, "fd-navigation-cell"]
          }
        ];
        // console.log(`***\nallHeaders`);
        // allHeaders.forEach(h => console.log(`\t ${h.value}: ${h.order}`));
        return allHeaders;
      }
    },
    headers(): TranslatableHeader[] {
      var sortedHeaders = this.allHeaders.sort((a, b) => a.order - b.order);
      // console.log(`***\nsortedHeaders`);
      // sortedHeaders.forEach(h => console.log(`\t ${h.value}: ${h.order}`));
      return sortedHeaders;
    },
    expanderColSpan(): number {
      if (this.$vuetify.breakpoint.lgAndUp) {
        return 14;
      } else if (this.$vuetify.breakpoint.xsOnly) {
        return 5;
      } else if (this.$vuetify.breakpoint.smOnly) {
        return 10;
      } else {
        return 14;
      }
    },

    baseWorkOrders(): WorkOrderForSchedulerGridWithDetails[] {
      return (this.allWorkOrders as any) as WorkOrderForSchedulerGridWithDetails[];
    },

    workOrdersWithDetails(): WorkOrderForSchedulerGridWithDetails[] {
      // TODO: Show loading indicator if any of these aren't loaded yet
      return this.baseWorkOrders.map(x => {
        let workOrderDetailAddedData = this.workOrderDetailsByID?.get(x.id);
        if (!workOrderDetailAddedData) {
          workOrderDetailAddedData = { isLoading: true } as any;
        }
        let walkdownDetailAddedData = this.walkdownDetailsByID?.get(x.id);
        if (!walkdownDetailAddedData) {
          walkdownDetailAddedData = { isLoading: true } as any;
        }
        let workPackages = this.workPackagesByID?.get(x.id);
        if (!workPackages) {
          workPackages = undefined as any;
        }
        return {
          ...x,
          workOrderDetails: {
            ...x,
            ...workOrderDetailAddedData,
            workPackageNames: workPackages
              ? workPackages.map(x => x.workPackageName)
              : { isLoading: !this.workPackagesByID }
          } as any,
          walkdownDetails: walkdownDetailAddedData as any
        };
      });
    },

    // TODO: These crappy names/copy-paste jobs are because we're overwriting existing functionality of the workOrderList
    // If we wind up completely replaceing the workOrderList functionality we should circle back
    // and rename these back
    newBaseFilteredWorkOrders(): WorkOrderForSchedulerGridWithDetails[] {
      var filteredWorkOrders = this.workOrdersWithDetails;
      if (!this.showArchived) {
        // Some actions will archive the WO when the user updates it, which means it won't be returned on the next load
        // Hide it client side until that next load happens
        filteredWorkOrders = filteredWorkOrders.filter(x => !x.archivedDate);
      }
      if (this.requestorFilterIsMine) {
        // returns requests either submitted by the current user, or on the current user's behalf
        filteredWorkOrders = filteredWorkOrders.filter(
          x =>
            (!!x.coordinatorID && x.coordinatorID == this.curUserID) ||
            (!!x.generalForemanID && x.generalForemanID == this.curUserID) ||
            (!!x.foremanID && x.foremanID == this.curUserID)
        );
      }
      if (this.selectedRequestTypes.length) {
        filteredWorkOrders = filteredWorkOrders.filter(x =>
          valueInArray(x.requestType, this.selectedRequestTypes)
        );
      }
      if (this.selectedStatusNames.length) {
        filteredWorkOrders = filteredWorkOrders.filter(x =>
          valueInArray(
            WorkOrderStatusLabels[x.workOrderStatus]?.toLocaleUpperCase(),
            this.selectedStatusNames
          )
        );
      }
      if (this.selectedContractors.length) {
        filteredWorkOrders = filteredWorkOrders.filter(x =>
          valueInArray(x.assignedContractorID, this.selectedContractors)
        );
      }
      if (this.selectedDisciplines.length) {
        filteredWorkOrders = filteredWorkOrders.filter(x =>
          valueInArray(x.disciplineID, this.selectedDisciplines)
        );
      }
      if (this.selectedForemanIDs.length) {
        filteredWorkOrders = filteredWorkOrders.filter(x =>
          valueInArray(x.foremanID, this.selectedForemanIDs)
        );
      }
      if (this.selectedGeneralForemanIDs.length) {
        filteredWorkOrders = filteredWorkOrders.filter(x =>
          valueInArray(x.generalForemanID, this.selectedGeneralForemanIDs)
        );
      }
      if (this.selectedAreas.length) {
        filteredWorkOrders = filteredWorkOrders.filter(x =>
          valueInArray(x.areaID, this.selectedAreas)
        );
      }
      if (this.selectedSubAreas.length) {
        filteredWorkOrders = filteredWorkOrders.filter(x =>
          valueInArray(x.subAreaID, this.selectedSubAreas)
        );
      }
      return filterByTags<WorkOrderForSchedulerGridWithDetails & TaggableItem>(
        this.tagsSelectedForFiltering,
        filteredWorkOrders
      );
    },
    newAssignedWorkOrders(): WorkOrderForSchedulerGridWithDetails[] {
      let filteredWorkOrders = this.newBaseFilteredWorkOrders;

      // filter out requests which are not fully assigned and planned
      // showWorkOrderOnToDo is used since this state is dependant on the work order's status
      filteredWorkOrders = filteredWorkOrders.filter(
        x => !!x.foremanID && !!x.generalForemanID && !!this.getTodo(x)
      );

      return filteredWorkOrders;
    },
    newAssignedWorkOrdersCount(): string {
      return `${this.newAssignedWorkOrders.length}`;
    },
    newUnassignedWorkOrders(): WorkOrderForSchedulerGridWithDetails[] {
      let filteredWorkOrders = this.newBaseFilteredWorkOrders;

      // filter out requests which are fully assigned and planned
      // showWorkOrderOnToDo is used since this state is dependant on the work order's status
      filteredWorkOrders = filteredWorkOrders.filter(
        x => !x.foremanID || !x.generalForemanID || !this.getTodo(x)
      );
      return filteredWorkOrders;
    },
    newUnassignedWorkOrdersCount(): string {
      return `${this.newUnassignedWorkOrders.length}`;
    },
    newFilteredWorkOrders(): WorkOrderForSchedulerGridWithDetails[] {
      let filteredWorkOrders = this.newBaseFilteredWorkOrders;
      if (!this.showScaffoldRequests) {
        filteredWorkOrders = filteredWorkOrders.filter(
          wo =>
            wo.requestType != ScaffoldRequestTypes.Erect &&
            wo.requestType != ScaffoldRequestTypes.Modify &&
            wo.requestType != ScaffoldRequestTypes.Dismantle
        );
      }
      if (!this.showMaintenanceRequests) {
        filteredWorkOrders = filteredWorkOrders.filter(
          wo => wo.requestType != ScaffoldRequestTypes.Maintenance
        );
      }
      if (!this.showPaintRequests) {
        filteredWorkOrders = filteredWorkOrders.filter(
          wo => wo.requestType != ScaffoldRequestTypes.Paint
        );
      }
      if (!this.showInsulationRequests) {
        filteredWorkOrders = filteredWorkOrders.filter(
          wo => wo.requestType != ScaffoldRequestTypes.Insulation
        );
      }
      if (!this.includeUnassigned) {
        // filter out requests which are not fully assigned and planned
        // showWorkOrderOnToDo is used since this state is dependant on the work order's status
        filteredWorkOrders = filteredWorkOrders.filter(
          x => !!x.foremanID && !!x.generalForemanID && !!this.getTodo(x)
        );
      }
      if (!this.includeAssigned) {
        // filter out requests which are fully assigned and planned
        // showWorkOrderOnToDo is used since this state is dependant on the work order's status
        filteredWorkOrders = filteredWorkOrders.filter(
          x => !x.foremanID || !x.generalForemanID || !this.getTodo(x)
        );
      }
      return filteredWorkOrders;
    },
    forgottenWorkOrders(): WorkOrderForSchedulerGridWithDetails[] {
      if (!this.currentUserCanMoveWorkOrdersForward) return [];

      return this.workOrdersWithDetails.filter(
        x =>
          !!x.requiredDate &&
          x.requiredDate.getTime() < new Date(DateUtil.isoDateString(new Date())).getTime() &&
          x.workOrderStatus != WorkOrderStatuses.Completed &&
          x.workOrderStatus != WorkOrderStatuses.CompletionPendingAdministration &&
          x.workOrderStatus != WorkOrderStatuses.Cancelled
      );
    },
    newVisibleToDoWorkOrders(): WorkOrderForSchedulerGridWithDetails[] {
      return this.newVisibleWorkOrders.filter(x => this.getTodo(x));
    },
    newVisibleWorkOrders(): WorkOrderForSchedulerGridWithDetails[] {
      // The table uses the newFilteredWorkorders data and then filters it locally to change which rows are visible
      // We have no way to access this list of visible rows, so we replicate the functionality to have access to the same data
      var filteredWorkOrders = this.newFilteredWorkOrders;

      if (!!this.tablesearch?.length) {
        let dataTable = this.$refs.datatable as VDataTable;
        if (!!dataTable) {
          filteredWorkOrders = dataTable.customFilterWithColumns(
            filteredWorkOrders,
            this.tablesearch
          );
        }
      }
      return filteredWorkOrders;
    },

    filterStatusesWithCounts(): { name: string; count: number }[] {
      // TODO: This is a hacky hodge-podge way to get a list of statuses requiring string comparisons, we should normalize this somehow
      return this.allStatusNames.map(x => {
        return {
          name: x,
          count: this.allWorkOrders.filter(
            wo => WorkOrderStatusLabels[wo.workOrderStatus!]?.toUpperCase() == x
          ).length
        };
      });
    }
  },

  methods: {
    handleRowClick(item: WorkOrderForSchedulerGridWithDetails) {
      // We only want a single tap to navigate if we're in phone mode and the navigate chevron is visible
      if (this.$vuetify.breakpoint.smAndUp) return;
      this.$router.push(`/scheduler/${item.id}`);
    },
    handleRowDblClick(mouseEvent: MouseEvent, options: DataItemProps) {
      let item = options.item as WorkOrderForSchedulerGridWithDetails;
      if (!item) return;
      this.$router.push(`/scheduler/${item.id}`);
    },
    sortTheHeaders(evt: SortableEvent) {
      // console.log(`***\nsortTheHeaders evt: ${evt}`);
      // Get the number of hidden columns, so we can offset the index by this amount
      // "Show-Expand" is true, so add 1 to the hidden headers count
      const hiddenLeadingHeaderCount = this.allHeaders.filter(x => x.order < 0).length + 1;

      // Get the sorted visible headers
      // This array is sorted by the order, and then is going to get moved by the old/new indexes giving us the new order numbers
      // Also, doing the `.filter` gives us a new instance of the array instead of trying to modify the actual headers computed property
      const visibleHeaders = this.headers.filter(x => x.canReorder == true);

      // Indexes include ALL headers in the table, whether visible or not
      // Example: With 2 hidden columns and show-expand to true, the first visible column is actually the 4th column (index 3)
      let oldIndex = (evt.oldIndex ?? 0) - hiddenLeadingHeaderCount;
      let newIndex = (evt.newIndex ?? 0) - hiddenLeadingHeaderCount;

      // console.log(`${oldIndex} -> ${newIndex}`);
      if (oldIndex < 0) {
        // console.log(`Trying to move an invalid column (such as show-expand).  Ignore this`);
        return;
      }
      if (newIndex < 0) newIndex = 0;
      else if (newIndex >= visibleHeaders.length) newIndex = visibleHeaders.length - 1;
      // console.log(`*** ${oldIndex} -> ${newIndex}`);

      visibleHeaders.splice(newIndex, 0, visibleHeaders.splice(oldIndex, 1)[0]);

      for (let i = 0; i < visibleHeaders.length; i++) {
        const header = visibleHeaders[i];
        if (!header) {
          // console.log(`\t Header #${i} SKIPPED`);
          continue;
        }

        const value = header.value;
        if (this.headerOrders[value] == undefined) {
          // console.log(`\t ${this.headerOrders[value]} SKIPPED`);
          continue;
        }

        // console.log(`\t ${value}: ${this.headerOrders[value]} -> ${i}`);
        this.headerOrders[value] = i;
      }
      // Modifying the value in the `key` prop of the table forces it to refresh
      this.anIncreasingNumber += 1;

      this.updateSettings();
    },
    // Original downloadAndPrintPlannerReport method is called on the estimates list, so we have to replace it with a new one for here
    async newDownloadAndPrintPlannerReport(showOnlyToDos: boolean, reportType: string = "pdf") {
      this.processing = true;
      try {
        var visibleWorkOrders = this.newVisibleWorkOrders;
        if (showOnlyToDos) {
          visibleWorkOrders = this.newVisibleToDoWorkOrders;
        }
        if (visibleWorkOrders.length == 0) {
          this.$store.dispatch("SHOW_SNACKBAR", {
            text: this.$t("scheduler.printing.no-data-message"),
            type: "info"
          });
          return;
        }

        if (!this.workOrderDetailsByID) {
          await this.loadWorkOrderDetails();
        }
        if (!this.workPackagesByID) {
          await this.loadWorkPackageDetails();
        }

        await Promise.all([
          personDataStore.refresh(),
          disciplineDataStore.refresh(),
          locationDataStore.refresh()
        ]);

        let workOrdersToPrint = visibleWorkOrders.map(x => {
          var extraDetails = this.workOrderDetailsByID?.get(x.id);
          return {
            ...x,
            coordinatorName: personDataStore.lookupCaption(x.coordinatorID),
            generalForemanName: personDataStore.lookupCaption(x.generalForemanID),
            foremanName: personDataStore.lookupCaption(x.foremanID),
            workOrderStatusName: WorkOrderStatusLabels[x.workOrderStatus],
            areaName: locationDataStore.lookupCaption(x.areaID),
            subAreaName: locationDataStore.lookupCaption(x.subAreaID),
            disciplineName: disciplineDataStore.lookupCaption(x.disciplineID),
            requestingEmployeeName: !extraDetails?.requestingEmployeeID
              ? undefined
              : personDataStore.lookupCaption(extraDetails.requestingEmployeeID),
            requestTypeName: ScaffoldRequestTypeLabels[x.requestType],
            requestSubTypeName: !extraDetails?.requestSubType
              ? undefined
              : ScaffoldRequestSubTypeLabels[extraDetails.requestSubType],
            requestSubmitterName: !extraDetails?.requestSubmitterIDs?.length
              ? undefined
              : personDataStore.lookupCaption(extraDetails?.requestSubmitterIDs[0]),
            startDate: extraDetails?.startDate,
            specificWorkLocation: extraDetails?.specificWorkLocation,
            detailedWorkDescription: extraDetails?.detailedWorkDescription,
            requestSubmittedOn: extraDetails?.requestSubmittedOn,
            scaffoldNumber: x.scaffoldNumber,
            workPackages: this.workPackagesByID?.get(x.id),
            siteContact: extraDetails?.siteContact,
            isUrgentDetail: extraDetails?.isUrgentDetail,
            isPlanned: extraDetails?.isPlanned
          } as WorkOrderToPrint;
        });

        workOrdersToPrint = workOrdersToPrint.sort((a, b) => {
          // No matter how we sort, we want the urgent at the top
          let urgentA = a.isUrgent ?? false;
          let urgentB = b.isUrgent ?? false;
          if (urgentA != urgentB) {
            return urgentA ? -1 : 1;
          }

          // Urgency is the same, sort by priority ascending, with the lowest number at the top
          var priorityA = a.priority ?? 5;
          var priorityB = b.priority ?? 5;
          if (priorityA != priorityB) {
            return priorityA - priorityB;
          }

          // Priority of the items are the same, sort by required date ascing with the earliest required at the top
          // A lack of required date means it's not required and so goes to the bottom
          var requiredA = a.requiredDate ?? new Date(2999, 12, 31);
          var requiredB = a.requiredDate ?? new Date(2999, 12, 31);
          if (requiredA.getTime() != requiredB.getTime()) {
            return requiredA.getTime() - requiredB.getTime();
          }

          return 0;
        });

        var contractorFilterValue = !!this.selectedContractors?.length
          ? this.allContractors
              .filter(x => valueInArray(x.id!, this.selectedContractors))
              .map(x => x.name)
              .join(", ")
          : `${this.$t("common.all")}`;
        var disciplineFilterValue = !!this.selectedDisciplines?.length
          ? this.allDisciplines
              .filter(x => valueInArray(x.id!, this.selectedDisciplines))
              .map(x => x.name)
              .join(", ")
          : `${this.$t("common.all")}`;
        var areaFilterValue = !!this.selectedAreas?.length
          ? this.allAreas
              .filter(x => valueInArray(x.id!, this.selectedAreas))
              .map(x => x.name)
              .join(", ")
          : `${this.$t("common.all")}`;
        var subAreaFilterValue = !!this.selectedSubAreas?.length
          ? this.allSubAreas
              .filter(x => valueInArray(x.id!, this.selectedSubAreas))
              .map(x => x.name)
              .join(", ")
          : `${this.$t("common.all")}`;
        var generalForemanFilterValue = !!this.selectedGeneralForemanIDs?.length
          ? this.allGeneralForemen
              .filter(x => valueInArray(x.id!, this.selectedGeneralForemanIDs))
              .map(x => x.name)
              .join(", ")
          : `${this.$t("common.all")}`;
        var foremanFilterValue = !!this.selectedForemanIDs?.length
          ? this.allForemen
              .filter(x => valueInArray(x.id!, this.selectedForemanIDs))
              .map(x => x.name)
              .join(", ")
          : `${this.$t("common.all")}`;
        var statusFilterValue = !!this.selectedStatusNames?.length
          ? this.allStatusNames.filter(x => valueInArray(x, this.selectedStatusNames)).join(", ")
          : `${this.$t("common.all")}`;

        var unassignedSelected = this.includeUnassigned
          ? `${this.$t("common.yes")}`
          : `${this.$t("common.no")}`;
        var assignedSelected = this.includeAssigned
          ? `${this.$t("common.yes")}`
          : `${this.$t("common.no")}`;
        var onlyMineSelected = this.requestorFilterIsMine
          ? `${this.$t("common.yes")}`
          : `${this.$t("common.no")}`;
        var showOnlyToDosValue = showOnlyToDos
          ? `${this.$t("common.yes")}`
          : `${this.$t("common.no")}`;

        var blob = await reportService.getPlannerPrintoutReportContentWithData(
          workOrdersToPrint,
          reportType,
          contractorFilterValue,
          disciplineFilterValue,
          areaFilterValue,
          subAreaFilterValue,
          generalForemanFilterValue,
          foremanFilterValue,
          statusFilterValue,
          assignedSelected,
          unassignedSelected,
          onlyMineSelected,
          showOnlyToDosValue,
          DateUtil.localizedDateTimeString(new Date())
        );
        if (reportType == "xls") {
          downloadBlob(blob, "planner-printout.xlsx");
        } else {
          printBlob(blob, "planner-printout.pdf", "application/pdf");
        }
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
      }
    },
    async showWorkOrderMoveForwardDialog() {
      if (!this.currentUserCanMoveWorkOrdersForward) return;

      if (await showWorkorderFastForwardDialog(this.forgottenWorkOrders)) {
        await this.reloadTableData();
      }
    },
    async threeWeekLookAheadExport() {
      let date = await showThreeWeekLookAheadParametersDialog();
      if (!!date) {
        let weeklyWorkDays = this.$store.state.curEnvironment.weeklyWorkDays;
        // this.processing = true;
        // try {
        //   var now = new Date()
        //   var blob = await workOrderService.threeWeekLookAheadExport(this.newFilteredWorkOrders.map(wo => wo.id));
        //   downloadBlob(blob, `Three-Week-Look-Ahead-` + now.getFullYear() + '-' + now.getMonth().toString().padStart(2, "0") + '-'
        //                       + now.getDay().toString().padStart(2, "0") + `.xlsx`);
        // } catch (error) {
        //   this.handleError(error as Error);
        // } finally {
        //   this.processing = false;
        // }
      }
    },
    /*** GLOBAL ***/
    ...mapMutations({
      notifyNewBreadcrumb: "NOTIFY_NEW_BREADCRUMB",
      setFilteringContext: "SET_FILTERING_CONTEXT"
    }),

    async loadWorkOrders() {
      await Promise.all([
        this.reloadWorkOrders(),
        this.reloadWorkOrderDetails(),
        this.reloadWalkdownDetails(),
        this.reloadWorkPackageDetails()
      ]);
    },

    async reloadWorkOrders() {
      this.allWorkOrders = ((await workOrderSchedulerService.getWorkOrdersForSchedulerGrid(
        this.showArchived,
        this.showArchivedFromDate,
        this.showArchivedToDate
      )) as unknown) as WorkOrderWithExtraDetails[];
    },

    async loadWorkOrderDetails() {
      let workOrderDetails = Array.from(
        await workOrderSchedulerService.getWorkOrderDetailsForSchedulerGrid(
          this.showArchived,
          this.showArchivedFromDate,
          this.showArchivedToDate
        )
      );
      this.workOrderDetailsByID = new Map<string, WorkOrderExtraDetailsForSchedulerGrid>(
        workOrderDetails.map(x => [x.id, x])
      );
    },
    async reloadWorkOrderDetails() {
      if (this.workOrderDetailsByID != null) {
        await this.loadWorkOrderDetails();
      }
    },
    async loadWalkdownDetails() {
      let walkdownDetails = Array.from(
        await workOrderSchedulerService.getWalkdownDetailsForSchedulerGrid(
          this.showArchived,
          this.showArchivedFromDate,
          this.showArchivedToDate
        )
      );
      this.walkdownDetailsByID = new Map<string, WalkdownExtraDetailsForSchedulerGrid>(
        walkdownDetails.map(x => [x.id, x])
      );
    },
    async reloadWalkdownDetails() {
      if (this.walkdownDetailsByID != null) {
        await this.loadWalkdownDetails();
      }
    },
    async loadWorkPackageDetails() {
      let rawWorkPackages = await workOrderSchedulerService.getWorkPackagesForSchedulerGrid(
        this.showArchived,
        this.showArchivedFromDate,
        this.showArchivedToDate
      );
      let workPackagesByID = new Map<string, WorkOrderPackageForSchedulerGrid[]>();
      for (let rawWorkPackage of rawWorkPackages) {
        let existingWorkPackageList = workPackagesByID.get(rawWorkPackage.workOrderID);
        if (!existingWorkPackageList) {
          existingWorkPackageList = [rawWorkPackage];
          workPackagesByID.set(rawWorkPackage.workOrderID, existingWorkPackageList);
        } else {
          existingWorkPackageList.push(rawWorkPackage);
        }
      }
      this.workPackagesByID = workPackagesByID;
    },
    async reloadWorkPackageDetails() {
      if (this.walkdownDetailsByID != null) {
        await this.loadWorkPackageDetails();
      }
    },

    showRequiredDatePushedBadge(item: WorkOrderForSchedulerGridWithDetails): boolean {
      if (item.requiredDate && item.approvedRequiredDate) {
        return item.requiredDate.getTime() - item.approvedRequiredDate.getTime() > 0;
      } else {
        return false;
      }
    },

    requiredDatePushedBadgeContent(item: WorkOrderForSchedulerGridWithDetails): string {
      if (item.requiredDate && item.approvedRequiredDate) {
        let difference = item.requiredDate.getTime() - item.approvedRequiredDate.getTime();
        if (difference) {
          let days = Math.round(Math.abs(difference) / 86400000);
          if (days > 99) {
            return "99+";
          } else {
            return days.toString();
          }
        } else {
          return "";
        }
      } else {
        return "";
      }
    },

    itemExpanded(itemExpanded: { item: any; value: boolean }) {
      // TODO: Count expander closing? Does that change overall state?
      if (itemExpanded.value) {
        if (this.expanderExpandCount++ == 0) {
          if (!this.workOrderDetailsByID) {
            this.loadWorkOrderDetails();
            this.loadWalkdownDetails();
            this.loadWorkPackageDetails();
          }
        }
      }
    },

    searchFilter(
      value: any,
      search: string | null,
      item: WorkOrderForSchedulerGridWithDetails
    ): boolean {
      // HACK: Search customization in Vuetify 2 is byzantine at best...
      // This gets called once per column but we have no idea which column we're acting on so we
      // can't figure out what we should do to convert the value; we'll basically just check to
      // see if this search is for the ID field and if it is we'll do the full check, if it's not
      // we'll return "not found" until we get to our field
      if (item.id !== value) return false;
      if (!search) return true;
      search = search.toLocaleLowerCase();

      let searchStrings = [
        item.id?.toString(),
        item.internalNumber?.toString(),
        item.scaffoldNumber?.toString(),
        item.clientWorkOrderReferenceNumber,
        ScaffoldRequestTypeLabels[item.requestType],
        ScaffoldRequestSubTypeLabels[item.requestSubType],
        WorkOrderStatusLabels[item.workOrderStatus],
        personDataStore.lookupCaption(item.coordinatorID),
        personDataStore.lookupCaption(item.generalForemanID),
        personDataStore.lookupCaption(item.foremanID),
        contractorDataStore.lookupCaption(item.assignedContractorID)
      ];
      for (let searchString of searchStrings) {
        if (!searchString) continue;
        if (searchString.toLocaleLowerCase().includes(search)) return true;
      }
      return false;
    },

    getTodo(item: WorkOrderForSchedulerGridWithDetails) {
      switch (item.workOrderStatus) {
        case WorkOrderStatuses.Walkdown:
        case WorkOrderStatuses.Estimated:
          return !!item.plannedWalkdownStartDate;
        default:
          return !!item.plannedWorkStartDate;
      }
    },

    async flipTodo(item: WorkOrderForSchedulerGridWithDetails) {
      switch (item.workOrderStatus) {
        case WorkOrderStatuses.Approved:
          let snackbarPayload = {
            text: this.$t("scheduler.cannot-mark-approved-to-do", [item.internalNumber]),
            type: "error",
            undoCallback: null
          };
          this.$store.dispatch("SHOW_SNACKBAR", snackbarPayload);
          return;
        case WorkOrderStatuses.Walkdown:
        case WorkOrderStatuses.Estimated:
          await this.flipWalkdownPlanned(item);
          return;
        default:
          await this.flipWorkPlanned(item);
          return;
      }
    },

    async flipWalkdownPlanned(item: WorkOrderForSchedulerGridWithDetails) {
      console.log(`flipWalkdownPlanned WorkOrder ID: ${item.internalNumber}`);

      this.inlineMessage.message = null;
      this.processing = true;
      this.updatingWorkOrderID = item.id;
      try {
        // We want to use the opposite value for archived, since we're flipping it
        var plannedWalkdownStartDate = !!item.plannedWalkdownStartDate ? null : new Date();
        var updatedItem = await workOrderService.updateItem(
          item.id!,
          {
            id: item.id,
            plannedWalkdownStartDate: plannedWalkdownStartDate
          } as WorkOrderWithExtraDetails,
          "WorkOrderScheduler.flipWalkdownPlanned"
        );

        item.plannedWalkdownStartDate = updatedItem.plannedWalkdownStartDate || null;

        var snackbarPayload = {
          text: this.$t("scheduler.save-success", [item.internalNumber]),
          type: "success",
          undoCallback: null
        };
        this.$store.dispatch("SHOW_SNACKBAR", snackbarPayload);
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
        this.updatingWorkOrderID = null;
      }
    },

    async flipWorkPlanned(item: WorkOrderForSchedulerGridWithDetails) {
      console.log(`flipWorkPlanned WorkOrder ID: ${item.internalNumber}`);
      this.processing = true;
      if (
        item.requestType == ScaffoldRequestTypes.Dismantle &&
        item.activeWorkOrders! > 1 &&
        !item.plannedWorkStartDate
      ) {
        // Dismantle work order for a scaffold that still has other active WOs
        // Work is not already planned (meaning we're turning it on)
        if (!(await this.openActiveWorkDetailsForScaffoldDialog(item, true))) {
          // Change the value to something else and then back to its current to force a rebind
          this.processing = false;
          return;
        }
      }

      if (!item.plannedWorkStartDate) {
        // If it's not planned (therefore it's BECOMING planned) validate data.
        if (!item.generalForemanID || !item.foremanID) {
          var snackbarPayload = {
            text: this.$t("scheduler.cannot-mark-unassigned-to-do", [item.internalNumber]),
            type: "error",
            undoCallback: null
          };
          this.$store.dispatch("SHOW_SNACKBAR", snackbarPayload);
          this.processing = false;
          return;
        }
      }

      this.inlineMessage.message = null;
      this.processing = true;
      this.updatingWorkOrderID = item.id;
      try {
        // We want to use the opposite value for archived, since we're flipping it
        var plannedWorkStartDate = !!item.plannedWorkStartDate ? null : new Date();
        var updatedItem = await workOrderService.updateItem(
          item.id!,
          {
            id: item.id,
            plannedWorkStartDate: plannedWorkStartDate
          } as WorkOrderWithExtraDetails,
          "WorkOrderScheduler.flipWorkPlanned"
        );

        item.plannedWorkStartDate = updatedItem.plannedWorkStartDate || null;

        var snackbarPayload = {
          text: this.$t("scheduler.save-success", [item.internalNumber]),
          type: "success",
          undoCallback: null
        };
        this.$store.dispatch("SHOW_SNACKBAR", snackbarPayload);
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
        this.updatingWorkOrderID = null;
      }
    },

    async flipArchived(item: WorkOrderWithExtraDetails) {
      this.inlineMessage.message = null;
      this.processing = true;
      this.updatingWorkOrderID = item.id;
      try {
        // Flipping the archived switch has no effect on the SP1 work order, so we can do a basic update here
        var updatedItem = await workOrderService.updateItem(
          item.id!,
          {
            id: item.id,
            isArchived: !item.isArchived
          } as WorkOrderWithExtraDetails,
          "WorkOrderScheduler.flipArchived"
        );

        item.archivedDate = updatedItem.archivedDate;

        // TODO: If the user is hitting this flag now it's unlikely we have to worry about post-dated archive flags
        var utcNow = new Date(
          new Date().toUTCString().substring(0, new Date().toUTCString().length - 4)
        );
        // divide by 1000 to get rid of milliseconds.  Also provide a bit of buffer for time differences
        item.isArchived =
          updatedItem.archivedDate != null &&
          updatedItem.archivedDate.getTime() / 60000 <= utcNow.getTime() / 60000;

        var snackbarPayload = {
          text: this.$t("scheduler.save-success", [item.internalNumber]),
          type: "success",
          undoCallback: null
        };
        this.$store.dispatch("SHOW_SNACKBAR", snackbarPayload);
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
        this.updatingWorkOrderID = null;
      }
    },

    async updateWorkOrderProgress(item: WorkOrderForSchedulerGridWithDetails, progress: number) {
      // If the user is setting this to 100%, the WO is going to be marked as completed and archived
      // As such, we want to present the undo option
      await this.saveField(item, "progress", progress, progress >= 100);
    },

    itemMustStayArchived(item: WorkOrderForSchedulerGridWithDetails): boolean {
      return (
        item.workOrderStatus == WorkOrderStatuses.Completed ||
        item.workOrderStatus == WorkOrderStatuses.Cancelled
      );
    },

    async updateWorkOrderStatus(
      item: WorkOrderForSchedulerGridWithDetails,
      workOrderStatus: WorkOrderStatuses
    ) {
      var allowUndo = false;
      if (
        workOrderStatus == WorkOrderStatuses.Completed ||
        workOrderStatus == WorkOrderStatuses.Cancelled
      ) {
        // If the user is setting this to completed or cancelled, the WO is going to be archived and disappear from the list
        // As such, we want to present the undo option
        allowUndo = true;
      } else if (
        item.workOrderStatus == WorkOrderStatuses.Approved &&
        workOrderStatus != WorkOrderStatuses.Approved
      ) {
        // If the WO's current status is Approved and the user is setting it to anything, allow the undo
        // This is because "Approved" cannot be selected on this screen and therefore this is an irreversible action
        allowUndo = true;
      } else if (
        item.workOrderStatus == WorkOrderStatuses.Estimated &&
        workOrderStatus != WorkOrderStatuses.Estimated
      ) {
        // If the WO's current status is Estimated and the user is setting it to anything, allow the undo
        // This is because "Estimated" cannot be selected on this screen and therefore this is an irreversible action
        allowUndo = true;
      } else if (
        item.workOrderStatus == WorkOrderStatuses.InScheduling &&
        workOrderStatus != WorkOrderStatuses.InScheduling
      ) {
        // If the WO's current status is InScheduling and the user is setting it to anything, allow the undo
        // This is because "InScheduling" cannot be selected on this screen and therefore this is an irreversible action
        allowUndo = true;
      } else if (
        item.workOrderStatus == WorkOrderStatuses.Walkdown &&
        workOrderStatus != WorkOrderStatuses.Approved
      ) {
        // If the WO's current status is WALKDOWN and the user is setting it to anything other than Approved, allow the undo
        // This is because "Walkdown" cannot be selected on this screen unless in approved status and therefore this is an irreversible action
        allowUndo = true;
      }

      await this.saveField(item, "workOrderStatus", workOrderStatus, allowUndo);
    },

    async saveField(
      item: WorkOrderForSchedulerGridWithDetails,
      fieldName: string,
      value: any,
      presentUndo: boolean = false
    ) {
      var canMakeChange = true;
      if (!this.currentUserCanEditWorkOrderSchedule) canMakeChange = false;

      if (fieldName == "areaID" && !item.currentUserPermissions.canEditArea) canMakeChange = false;
      if (fieldName == "subAreaID" && !item.currentUserPermissions.canEditArea)
        canMakeChange = false;
      if (fieldName == "siteContact" && !item.currentUserPermissions.canEditSiteContact)
        canMakeChange = false;
      if (fieldName == "specificWorkLocation" && !item.currentUserPermissions.canEditLocation)
        canMakeChange = false;
      if (
        fieldName == "detailedWorkDescription" &&
        !item.currentUserPermissions.canEditWorkDescription
      )
        canMakeChange = false;
      if (fieldName == "priority" && !item.currentUserPermissions.canEditPriority)
        canMakeChange = false;
      if (fieldName == "isUrgent" && !item.currentUserPermissions.canEditPriority)
        canMakeChange = false;
      if (
        (fieldName == "plannedWorkStartDate" || fieldName == "plannedWalkdownStartDate") &&
        !item.currentUserPermissions.canPushToToDoList
      )
        canMakeChange = false;
      if (fieldName == "progress" && !item.currentUserPermissions.canEditProgress)
        canMakeChange = false;
      if (
        fieldName == "workOrderStatus" &&
        !item.currentUserPermissions.canEditProgress &&
        (value == WorkOrderStatuses.Started || value == WorkOrderStatuses.Completed)
      )
        canMakeChange = false;
      if (
        fieldName == "assignedContractorID" &&
        !item.currentUserPermissions.canEditAssignedContractor
      )
        canMakeChange = false;
      if (fieldName == "coordinatorID" && !item.currentUserPermissions.canEditAssignedCoordinator)
        canMakeChange = false;
      if (
        fieldName == "generalForemanID" &&
        !item.currentUserPermissions.canEditAssignedGeneralForeman
      )
        canMakeChange = false;
      if (fieldName == "foremanID" && !item.currentUserPermissions.canEditAssignedForeman)
        canMakeChange = false;
      if (
        fieldName == "workOrderStatus" &&
        value != WorkOrderStatuses.Cancelled &&
        !item.currentUserPermissions.canEditStatus
      )
        canMakeChange = false;
      if (
        fieldName == "workOrderStatus" &&
        value == WorkOrderStatuses.Cancelled &&
        !item.currentUserPermissions.canCancel
      )
        canMakeChange = false;
      if (fieldName == "requiredDate" && !item.currentUserPermissions.canEditRequiredDate)
        canMakeChange = false;

      // Capture the original value. This allows us to set the value of the client-side item immediately while still being able to revert it on error
      var originalValue = (item as any)[fieldName];

      this.inlineMessage.message = null;
      this.processing = true;
      this.updatingWorkOrderID = item.id;
      try {
        let updatedItem = {
          id: item.id,
          [fieldName]: value,
          archivedDate: item.archivedDate // Status changes rely on this to be passed in, and this property is not checked directly for modified
        } as WorkOrderWithExtraDetails;

        if (item.requestType == ScaffoldRequestTypes.Dismantle && item.activeWorkOrders! > 1) {
          if (
            (fieldName == "isUrgent" && value == true) ||
            (fieldName == "workOrderStatus" &&
              (value == WorkOrderStatuses.Started || value == WorkOrderStatuses.Completed))
          ) {
            if (!(await this.openActiveWorkDetailsForScaffoldDialog(item, true))) {
              // Change the value to something else and then back to its current to force a rebind
              let previousUrgent = item.isUrgent;
              item.isUrgent = !item.isUrgent;
              let previousStatus = item.workOrderStatus;
              item.workOrderStatus = 0 as WorkOrderStatuses;
              this.$nextTick(() => {
                item.isUrgent = previousUrgent;
                item.workOrderStatus = previousStatus;
              });
              return false;
            }
          }
        }

        if (fieldName == "assignedContractorID") {
          var assignedContractorID = value as string;
          if (!assignedContractorID) {
            updatedItem.coordinatorID = null;
            updatedItem.generalForemanID = null;
            updatedItem.foremanID = null;
          } else {
            var foundAssignableCoordinator = this.getAssignableCoordinatorsForWorkOrder(item).find(
              x => (x as PersonWithName)?.id == item.coordinatorID
            ) as PersonWithName;
            updatedItem.coordinatorID = foundAssignableCoordinator?.id ?? null;
            updatedItem.generalForemanID =
              this.getGeneralForemenForContractor(assignedContractorID).find(
                x => x.id == item.generalForemanID
              )?.id ?? null;
            updatedItem.foremanID =
              this.getForemenForContractor(assignedContractorID).find(x => x.id == item.foremanID)
                ?.id ?? null;
          }
        } else if (fieldName == "isUrgent") {
          let details = "" as string | undefined;
          if (value == true) {
            let title = this.$t("scheduler.urgent-reason");

            details = await showAdditionalDetailsDialog(title, this.$t("common.reason"), [
              this.rules.required
            ]);

            // If details is undefined the dialog was cancelled
            if (!details) {
              // Change the value to something else and then back to its current to force a rebind
              let previousUrgent = item.isUrgent;
              item.isUrgent = !item.isUrgent;
              this.$nextTick(() => {
                item.isUrgent = previousUrgent;
              });
              return false;
            }
          }
          updatedItem.isUrgentDetail = details;
        } else if (fieldName == "workOrderStatus") {
          let details = "" as string | undefined;
          if (value == WorkOrderStatuses.OnHold || value == WorkOrderStatuses.Cancelled) {
            var title = this.$t("scheduler.status-reason");
            if (value == WorkOrderStatuses.OnHold) title = this.$t("scheduler.on-hold-reason");
            if (value == WorkOrderStatuses.Cancelled)
              title = this.$t("scheduler.cancellation-reason");

            details = await showAdditionalDetailsDialog(title, this.$t("common.reason"), [
              this.rules.required
            ]);

            // If details is undefined the dialog was cancelled
            if (!details) {
              // Change the value to something else and then back to its current to force a rebind
              var previous = item.workOrderStatus;
              item.workOrderStatus = 0 as WorkOrderStatuses;
              this.$nextTick(() => {
                item.workOrderStatus = previous;
              });
              return false;
            }
          }
          updatedItem.workOrderStatusDetail = details;
        }

        // We've cached the item's originalValue above to use in case of error
        // So we can set the item's value here so the UI updates while the call happens
        (item as any)[fieldName] = value;
        var loadedItem = await workOrderService.updateItem(
          item.id!,
          updatedItem,
          `WorkOrderScheduler.saveField.${fieldName}`
        );

        // The following fields might have been updated via business logic when doing the update
        // They could be updated via various fields and don't hurt anything to set again based on the loadedItem details
        if (loadedItem.workOrderStatus !== undefined)
          item.workOrderStatus = loadedItem.workOrderStatus;
        (item as any).workOrderStatusDetail = loadedItem.workOrderStatusDetail;
        if (loadedItem.archivedDate !== undefined) item.archivedDate = loadedItem.archivedDate;
        if (loadedItem.progress !== undefined) item.progress = loadedItem.progress;
        var utcNow = new Date(
          new Date().toUTCString().substring(0, new Date().toUTCString().length - 4)
        );
        (item as any).isArchived =
          loadedItem.archivedDate != null &&
          loadedItem.archivedDate.getTime() / 60000 <= utcNow.getTime() / 60000;
        if (fieldName == "requiredDate") {
          if (loadedItem.requiredDate !== undefined) item.requiredDate = loadedItem.requiredDate;
          if (loadedItem.approvedRequiredDate !== undefined)
            item.approvedRequiredDate = loadedItem.approvedRequiredDate;
          (item as any).requiredDatePushed = CalculateRequiredDateDaysPushed(
            loadedItem.requiredDate,
            loadedItem.approvedRequiredDate
          );
        } else if (fieldName == "assignedContractorID") {
          if (loadedItem.coordinatorID !== undefined) item.coordinatorID = loadedItem.coordinatorID;

          if (loadedItem.generalForemanID !== undefined)
            item.generalForemanID = loadedItem.generalForemanID;

          if (loadedItem.foremanID !== undefined) item.foremanID = loadedItem.foremanID;

          var contractor = this.allContractors.find(x => item.assignedContractorID == x.id);
          if (!!contractor) {
            (item as any).assignedContractorName = contractor.name!;
          } else {
            (item as any).assignedContractorName = "";
          }

          var coordinator = this.allCoordinators.find(x => item.coordinatorID == x.id);
          if (!!coordinator) {
            (item as any).coordinatorName = GetPersonName(coordinator);
          } else {
            (item as any).coordinatorName = "";
          }

          var generalForeman = this.allGeneralForemen.find(x => item.generalForemanID == x.id);
          if (!!generalForeman) {
            (item as any).generalForemanName = GetPersonName(generalForeman);
          } else {
            (item as any).generalForemanName = "";
          }

          var foreman = this.allForemen.find(x => item.foremanID == x.id);
          if (!!foreman) {
            (item as any).foremanName = GetPersonName(foreman);
          } else {
            (item as any).foremanName = "";
          }
        } else if (fieldName == "coordinatorID") {
          var coordinator = this.allCoordinators.find(x => item.coordinatorID == x.id);
          if (!!coordinator) {
            (item as any).coordinatorName = GetPersonName(coordinator);
          } else {
            (item as any).coordinatorName = "";
          }
        } else if (fieldName == "foremanID") {
          var foreman = this.allForemen.find(x => item.foremanID == x.id);
          if (!!foreman) {
            (item as any).foremanName = GetPersonName(foreman);
          } else {
            (item as any).foremanName = "";
          }
        } else if (fieldName == "generalForemanID") {
          var generalForeman = this.allGeneralForemen.find(x => item.generalForemanID == x.id);
          if (!!generalForeman) {
            (item as any).generalForemanName = GetPersonName(generalForeman);
          } else {
            (item as any).generalForemanName = "";
          }
        } else if (fieldName == "workOrderStatus") {
          var status = this.allWorkOrderStatuses.find(x => x.value == item.workOrderStatus);
          if (!!status) {
            (item as any).workOrderStatusName = status.displayName;
          } else {
            (item as any).workOrderStatusName = "";
          }
        } else if (fieldName == "isUrgent") {
          (item as any).isUrgentDetail = updatedItem.isUrgentDetail;
          if (value == true) {
            (item as any).lastUrgentValueChangedBy = this.curUserFullName;
            (item as any).lastUrgentValueChangedDate = new Date();
          }
        }

        let undoCallback = null;
        if (presentUndo) {
          undoCallback = async () => {
            this.saveField(item, fieldName, originalValue, false);
          };
        }
        let snackbarPayload = {
          text: this.$t("scheduler.save-success", [item.internalNumber]),
          type: "success",
          undoCallback: undoCallback
        };
        this.$store.dispatch("SHOW_SNACKBAR", snackbarPayload);
      } catch (error) {
        // Change the value to something else and then back to its original value to force a rebind to undo the change the user made
        (item as any)[fieldName] = undefined;
        this.$nextTick(() => {
          (item as any)[fieldName] = originalValue;
        });

        // If the error is a 422 (meaning server validation failed) show a snackbar with the appropriate error message
        // If it's anything, handle as normal
        if ((error as any).statusCode == 422) {
          let snackbarPayload = {
            text: this.$t(`error-messages.${(error as ServiceError).message}`, [
              item.internalNumber
            ]),
            type: "error",
            undoCallback: null
          };
          this.$store.dispatch("SHOW_SNACKBAR", snackbarPayload);
        } else {
          this.handleError(error as Error);
        }
      } finally {
        this.processing = false;
        this.updatingWorkOrderID = null;
      }
    },

    async openActiveWorkDetailsForScaffoldDialog(
      item: WorkOrderForSchedulerGridWithDetails,
      startingDismantleWorkOrder: boolean
    ): Promise<boolean> {
      // TODO: Revise this function to remove dead/unused code and use compatible local types
      // We copied this from other places for expedience but haven't really reckoned with how
      // this form's data is different from elsewhere in the system; we do the shortcut of casting
      // values through `unknown` because we don't seem to need a lot of the extra data but this
      // isn't terribly robust; we should define tighter data contracts and apply them
      var activeWorkOrders = [] as WorkOrderWithExtraDetails[];
      var workOrderIDs: string[] = [];
      // var workOrderIDs = item.scaffoldActiveWorkOrderIDs ?? [];
      // workOrderIDs.forEach(otherWorkOrderId => {
      //   var loadedWorkOrder = this.allWorkOrders.find(x => x.id == otherWorkOrderId);
      //   if (!!loadedWorkOrder) {
      //     activeWorkOrders.push(loadedWorkOrder);
      //   }
      // });
      // If the current item isn't included, that means there's an active request instead
      // However we still include it so that something shows.
      // The dialog will replace it after loading the data anyway
      if (!workOrderIDs.includes(item.id!))
        activeWorkOrders.push((item as unknown) as WorkOrderWithExtraDetails);

      var convertedWorkOrders = activeWorkOrders.map(x => WorkForScaffoldDetailsFromWorkOrder(x));
      let result = await openActiveWorkForScaffoldDialog(
        item.scaffoldNumber,
        convertedWorkOrders,
        item.id,
        startingDismantleWorkOrder,
        false, // This is never approving a request
        false
      );
      if (!startingDismantleWorkOrder && result) {
        await this.reloadTableData();
      }
      return result;
    },

    async updateSettings() {
      await personalSettingService.updateItemForCurrentPerson({
        workOrderSchedulerColumnsJson: JSON.stringify(this.headerOrders)
      } as PersonalSetting);
    }
  },

  created: async function() {
    var requestorFilter: FilteringRequestorContext = !!this.curUserAccess.homeContractorID
      ? "mine"
      : "all";
    // Set the context for the User Filtering in the store so that if the user navigates to a screen that is
    // a sub screen of something that is currently filtered by their choices that those choices will be
    // preserved as they move between the two screens.
    var toDate = DateUtil.addDaysToDate(null, 0);
    this.setFilteringContext({
      context: "scheduler",
      parentalContext: null,
      showArchivedForFiltering: false,
      showArchivedForFilteringFromDate: DateUtil.addMonthsToDate(toDate, -2),
      showArchivedForFilteringToDate: toDate,
      searchStringForFiltering: "",
      tagsForFiltering: [],
      requestTypesForFiltering: [],
      statusesForFiltering: [],
      contractorsForFiltering: [],
      disciplinesForFiltering: [],
      foremanIDsForFiltering: [],
      generalForemanIDsForFiltering: [],
      areasForFiltering: [],
      subAreasForFiltering: [],
      contextForFiltering: {
        requestorFilter: requestorFilter,
        unassignedFilter: true,
        assignedFilter: true,
        showScaffoldRequests: true,
        showMaintenanceRequests: this.curUserAccess.canViewMaintenanceJobs,
        showPaintRequests: this.curUserAccess.canViewPaintJobs,
        showInsulationRequests: this.curUserAccess.canViewInsulationJobs
      } as FilteringContext
    });

    this.notifyNewBreadcrumb({
      text: this.$t("scheduler.list-title"),
      to: "/scheduler",
      resetHistory: true
    });

    this.processing = true;
    try {
      await Promise.all([
        this.loadDisciplines(),
        this.loadCurrentUserDisciplines(),
        this.loadAreas(),
        this.loadSubAreas(),
        this.loadCoordinators(),
        this.loadGeneralForemen(),
        this.loadForemen(),
        this.loadContractors()
      ]);
      this.processing = true;

      let settings = await personalSettingService.getForCurrentPerson();
      if (!!settings?.workOrderSchedulerColumnsJson?.length)
        this.headerOrders = JSON.parse(settings.workOrderSchedulerColumnsJson);

      this.allWorkOrderStatuses = await workOrderStatusHelper.getSchedulerSelectableWorkOrderStatuses();
      this.allStatusNames = this.allWorkOrderStatuses.map(x => x.displayName);

      this.scaffoldContractors = this.allContractors.filter(x => !!x.isScaffoldCompany);
      this.paintContractors = this.allContractors.filter(x => !!x.isPaintCompany);
      this.insulationContractors = this.allContractors.filter(x => !!x.isInsulationCompany);
      this.maintenanceContractors = this.allContractors.filter(x => !!x.isMaintenanceCompany);

      await this.reloadTableData();

      // double check selectedRequestor filter based on table results
      var myWorkOrders = this.allWorkOrders.filter(
        x =>
          (!!x.coordinatorID && x.coordinatorID == this.curUserID) ||
          (!!x.generalForemanID && x.generalForemanID == this.curUserID) ||
          (!!x.foremanID && x.foremanID == this.curUserID)
      );
      if (this.requestorFilterIsMine && myWorkOrders.length == 0) {
        this.requestorFilterIsMine = false;
      } else if (!this.requestorFilterIsMine && myWorkOrders.length > 0) {
        this.requestorFilterIsMine = true;
      }
    } catch (error) {
      if ((error as any).statusCode == 403) {
        this.inlineMessage.message = "";
      } else {
        this.handleError(error as Error);
      }
    } finally {
      this.processing = false;
    }
  }
});

