import FDVue from "@fd/lib/vue";
import dialogSupport, { createDialog } from "@fd/lib/vue/mixins/dialogSupport";
import rules from "../../../../../lib/vue/rules";
import { isoDateString } from "../../../../../lib/client-util/datetime";

type ThreeWeekLookAheadParametersDialogResult = Date | undefined;

const ThreeWeekLookAheadParametersDialog = FDVue.extend({
  name: "sp-three-week-look-ahead-parameters-dialog",

  mixins: [dialogSupport, rules],

  components: {},
  directives: {},

  data() {
    return {
      date: new Date()
    };
  },

  computed: {
    unwatchedMethodNames(): string[] {
      return ["open"];
    }
  },

  watch: {},

  mounted: async function() {},

  methods: {
    async open(): Promise<ThreeWeekLookAheadParametersDialogResult> {
      return await this.showDialog();
    },

    // Method used in conjunction with the Cancel dialog.
    cancelDialog() {
      this.closeDialog!(undefined);
    },

    async acceptDialog() {
      this.inlineMessage.message = null;
      this.processing = true;
      try {
        this.closeDialog(this.date);
      } catch (error) {
      } finally {
        this.processing = false;
      }
    }
  }
});

export default ThreeWeekLookAheadParametersDialog;

export async function showThreeWeekLookAheadParametersDialog(): Promise<
  ThreeWeekLookAheadParametersDialogResult
> {
  let dialog = createDialog(ThreeWeekLookAheadParametersDialog);
  dialog.optOutOfErrorHandling();
  let result = await dialog.open();
  console.log("showThreeWeekLookAheadParametersDialog.result: " + JSON.stringify(result));
  return result;
}

